import { Component, OnDestroy } from '@angular/core';
import { UserError } from '@grip/shared/models/user-provisioning.model';
import { UserSession } from '@grip/shared/models/user-session.model';
import { DigitalDataService } from '@grip/shared/services/digital-data.service';
import { ProvisioningService } from '@grip/shared/services/provisioning.service';
import { StateService } from '@grip/shared/services/state.service';
import { ZodUserSessionArray } from '@grip/shared/zod-validators/session.validator';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { MenuDataService } from '../../shared/services/menu-data.service';

@Component({
  selector: 'grip-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  public provisioningOpen: boolean = false;
  public showProvisioning: boolean = false;
  public showErrorProvisioning: boolean = false;
  public provisioningProgressPercentage: number = 0;
  public doProvisioningAnimation: boolean = false;
  public currentSessions: ZodUserSessionArray = [];
  private subscription: Subscription = new Subscription();

  constructor(
    public readonly stateService: StateService,
    public readonly digitalDataService: DigitalDataService,
    public readonly menuDataService: MenuDataService,
    private readonly provisioningService: ProvisioningService
  ) {
    this.subscription.add(
      this.provisioningService.provisioningQueueStatus.subscribe((queue) => {
        if (queue?.data?.totalCalls) {
          this.showProvisioning = true;
          this.doProvisioningAnimation = true;
          this.showErrorProvisioning = false;
          this.provisioningProgressPercentage = Math.floor(((queue.data.failed + queue.data.success) / queue.data.totalCalls) * 100);
          if (queue.data?.failed > 0) {
            this.provisioningOpen = true;
          }
        } else {
          this.showProvisioning = false;
          this.doProvisioningAnimation = false;
        }
      })
    );

    this.subscription.add(
      this.provisioningService.provisioningError.subscribe((error: UserError) => {
        this.showErrorProvisioning = !error;
      })
    );
    this.getSessions();
  }

  public getSessions(): void {
    this.currentSessions = this.stateService.getCurrentSessions();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public logout(): void {
    this.stateService.removeSession();
    window.location.href = this.menuDataService.logoutUrl;
  }

  public selectUser(user: UserSession): void {
    window.location.href = `${window.location.origin}/a/${user.tenant.shortName}`;
  }

  public addAnotherAccount(): void {
    window.location.href = `${environment.rootIdpUrl}&prompt=login select_account`;
  }

  public goToProfile(): void {
    window.open(`${window.location.origin}/account`, '_blank').focus();
  }

  public forgotPassword(): void {
    window
      .open(
        `${window.location.origin}/password-reset/options?tenantId=${this.stateService.tenant.id}&userId=${this.stateService.userId}`,
        '_blank'
      )
      .focus();
  }
}
