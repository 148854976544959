import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItemContainerModel, MenuItemModel, MenuItemType } from '@grip/shared/models/menu-item.model';
import { DigitalDataService } from '@grip/shared/services/digital-data.service';
import { MenuDataService } from '@grip/shared/services/menu-data.service';
import { StateService } from '@grip/shared/services/state.service';
import { GripMessageService } from '@kpn-grip-fe/core';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { MenuService } from '../../shared/services/menu.service';

@Component({
  selector: 'grip-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  public menu: MenuItemModel[];
  private activeMenuItem: MenuItemModel;
  private subscriptions: Subscription = new Subscription();
  private tabGuardedPendingNavigation: MenuItemModel = null;

  constructor(
    public readonly stateService: StateService,
    public readonly digitalDataService: DigitalDataService,
    public readonly menuDataService: MenuDataService,
    private readonly menuService: MenuService,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.menuService.activeMenu$.subscribe((menu) => {
        this.menu = menu.menuItems;
      })
    );
    this.subscriptions.add(
      this.menuService.activeMenuItem$.subscribe((item) => {
        if (item === null || item === undefined) {
          this.activeMenuItem = null;
        }
        if (!item) {
          return;
        }
        this.stateService.pageTitle = item.title;
        this.activeMenuItem = item;
      })
    );
    this.subscriptions.add(
      GripMessageService.tabGuardDone$.subscribe(() => {
        this.stateService.tabGuardActive = false;
        if (this.tabGuardedPendingNavigation) {
          this.goToItem(this.tabGuardedPendingNavigation);
        }
        this.tabGuardedPendingNavigation = null;
      })
    );
    window.addEventListener('message', this.handleTabGuardFromMono.bind(this));
  }

  public handleTabGuardFromMono(item: MessageEvent): void {
    if (item.data.type === 'TabGuardActiveFromMono') {
      this.menuService.activeTabGuardFromMono = item.data.value;
    }
    if (item.data.type === 'TabGuardFinishFromMono') {
      this.menuService.activeTabGuardFromMono = false;
      this.goToItem(this.tabGuardedPendingNavigation);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public isContainer(item: MenuItemModel): boolean {
    return item.menuItemType === MenuItemType.Container;
  }

  public isExternalLink(item: MenuItemModel): boolean {
    return item.menuItemType === MenuItemType.Url;
  }

  public getChildren(item: MenuItemContainerModel): MenuItemModel[] {
    return item.subMenu;
  }

  public isContainerOpen(item: MenuItemContainerModel): boolean {
    if (!this.activeMenuItem) {
      return false;
    }
    const activeItemIsThisContainer = this.activeMenuItem.route === item.route;
    const parentItem = this.menuService.getParent(this.activeMenuItem);
    const activeItemParentIsThisContainer = parentItem && parentItem.route === item.route;
    return activeItemIsThisContainer || activeItemParentIsThisContainer;
  }

  public isActive(item: MenuItemModel): boolean {
    if (!!this.stateService.blockingError) {
      return false;
    }
    let active = this.activeMenuItem && this.activeMenuItem.route === item.route;
    const containerItem = item as MenuItemContainerModel;
    if (!active && containerItem.menuItemType === MenuItemType.Container && containerItem.subMenu && containerItem.subMenu.length) {
      active = containerItem.subMenu.some((child) => (this.activeMenuItem && child ? this.activeMenuItem.route === child.route : false));
    }
    return active;
  }

  public goToItem(item?: MenuItemModel): void {
    if (!item) {
      return;
    }
    if (this.stateService.tabGuardActive) {
      GripMessageService.triggerTabGuard$.next();
      this.tabGuardedPendingNavigation = item;
    } else if (this.menuService.activeTabGuardFromMono) {
      this.menuService.activeTabGuardTriggerFromMono$.emit();
      this.tabGuardedPendingNavigation = item;
    } else {
      this.router.navigateByUrl(this.getRouterLink(item));
    }
    this.stateService.mobileMenuOpen = false;
    if (item && this.activeMenuItem && !this.menuService.activeTabGuardFromMono && item.route === this.activeMenuItem.route) {
      this.menuService.setInitialIframeRoute();
    }
  }

  public addAnotherAccount(): void {
    window.location.href = `${environment.rootIdpUrl}&prompt=login select_account`;
  }

  public goToProfile(): void {
    window.open(`${window.location.origin}/account`, '_blank').focus();
  }

  public getRouterLink(item: MenuItemModel): string {
    return this.menuService.getRouterLink(item);
  }

  public forgotPassword(): void {
    window
      .open(
        `${window.location.origin}/password-reset/options?tenantId=${this.stateService.tenant.id}&userId=${this.stateService.userId}`,
        '_blank'
      )
      .focus();
  }
}
